import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ApiSidebar from "../components/api-sidebar"
import ApiDocFooter from "../components/api-doc-footer"
import CodeBlock from "../components/code-block"
import { apiBaseUrl } from "../utils/api-settings"

const ApiPage = () => (
  <Layout title="The Quak Play API" sidebar={ApiSidebar()}>
    <SEO title="The Quak Play API" />

    <p>
      All Quak <Link to="/clients/">Clients</Link> use the Play API documented
      here. You can use the API by yourself to integrate Quak and play audio
      notifications wherever you want.
    </p>

    <h3>API Base URL</h3>
    <CodeBlock
      platform="bash"
      code={{
        bash: { code: apiBaseUrl },
      }}
      showPlatformPicker={false}
    />

    <h2>Install</h2>
    <p>
      <code>npm install typography</code>
    </p>
    <p>wording: targets, volume, preview, effects, ...</p>
    <p>api key, woher?, limits, error codes neue seite</p>
    <p>default settings. wo einsehen? cli, webapp!</p>
    <p>
      api wrapper: npm, ruby, python, php, response header, credits, time, uuid
    </p>
    <p>HTTPS only, no HTTP</p>
    <p>2 play exmaples. sound und text. auf sidebar verweisen.</p>
    <p>current API version: 1</p>
    <p>
      paste the curl commands into your terminal and try Quak API in seconds.
    </p>
    <p>enter, default values</p>

    <ApiDocFooter active="/api/" />
  </Layout>
)

export default ApiPage
